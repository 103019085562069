import React from "react";
import Accordion from "./accordion";
import { useTranslation } from "react-i18next";

export default function General() {
  const { t } = useTranslation();
  return(
    <>
      <div className="space-y-6">
        <h3 className="font-extrabold text-xl uppercase border-b-4 border-[#c3c3c3] pb-[10px] mt-4 mb-0">
          {t("general")}
        </h3>
        <Accordion title={t("faq-general.question-1")} content={t("faq-general.answer-1")}/>
        <Accordion title={t("faq-general.question-2")} content={t("faq-general.answer-2")}/>
        <Accordion title={t("faq-general.question-3")} content={t("faq-general.answer-3")}/>
        <Accordion title={t("faq-general.question-4")} content={t("faq-general.answer-4")}/>
        <Accordion title={t("faq-general.question-5")} content={t("faq-general.answer-5")}/>
      </div>
    </>
  )
}