import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalDialog from "../ModalDialog";
import useCrud from "../../hooks/useCRUD";
import { useForm } from "react-hook-form";

export default function Edition() {
	const { i18n, t } = useTranslation();

	const [modalOpen, setModalOpen] = useState(false);

	const { createResource, isLoading } = useCrud("download/presentation-book");
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		try {
			const res = await createResource(data, { lang: i18n.language });
			window.open(res.data.url, "_blank", "noopener,noreferrer");
			reset();
			setModalOpen(false);
		} catch (error) {}
	};

	return (
		<>
			<div className="bg-primary-gradient min-h-96 text-white py-16">
				<div className="flex flex-col lg:flex-row mx-8 lg:mx-32 gap-16 items-center">
					<div className="overflow-hidden rounded-lg lg:w-1/2">
						<img
							src="https://www.kerisjateng.id/assets/images/uploads/1233fede9b506c522e0bd643c642da7a.jpg"
							alt=""
							className="object-cover w-full h-full rounded-lg overflow-hidden"
						/>
					</div>
					<div className="lg:w-1/2">
						<h4 className="uppercase mb-4 font-bold tracking-widest">
							{t("book-edition")}
						</h4>
						<h1 className="mb-4 font-bold">{t("desc-presentation")}</h1>
						<h4 className="mb-4">{t("book-desc")}</h4>
						<div
							onClick={() => setModalOpen(true)}
							className="bg-white text-primary-dark cursor-pointer w-min px-6 py-3 rounded-lg font-bold"
						>
							<h6>{t("download")}</h6>
						</div>
					</div>
				</div>
			</div>

			<ModalDialog open={modalOpen} onOpenChange={setModalOpen}>
				<form onSubmit={handleSubmit(onSubmit)} className="w-full rounded-lg">
					<h4 className="text-black font-bold">{t("inputEmail")}</h4>
					<div className="w-full text-left mt-8">
						<label htmlFor="email" className="block text-gray-700">
							{t("email")}
						</label>
						<input
							type="text"
							id="email"
							placeholder={t("email")}
							className="w-full px-3 py-2 mt-2 border rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
							{...register("email", {
								required: t("requiredName"),
							})}
						/>
						{errors.email && (
							<span className="text-red-500 text-sm">
								{errors.email.message}
							</span>
						)}
					</div>
					{!isLoading && (
						<button type="submit" className="btn-primary mt-8 w-full">
							{t("download")}
						</button>
					)}
				</form>
			</ModalDialog>
		</>
	);
}
