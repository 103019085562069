import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosInstance from "../lib/axios";

const formatErrorMessages = (errors) => {
	const formattedErrors = {};

	Object.keys(errors).forEach((field) => {
		if (
			errors[field] &&
			Array.isArray(errors[field]) &&
			errors[field].length > 0
		) {
			formattedErrors[field] = errors[field][0] || "";
		} else {
			formattedErrors[field] = "";
		}
	});

	return formattedErrors;
};

const useCrud = (resource) => {
	const [resourceList, setResourceList] = useState(null);
	const [selectedResource, setSelectedResource] = useState(null);
	const [authToken, setAuthToken] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessages, setErrorMessages] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const storedAuthToken = Cookies.get("access_token");

		if (storedAuthToken) {
			setAuthToken(storedAuthToken);
		} else {
			setErrorMessages("Token not found");
		}
	}, []);

	const handleTokenExpiration = () => {
		Cookies.remove("access_token");
		setAuthToken(null);
		navigate("/login");
	};

	const getAllResources = async (filters = {}) => {
		try {
			setIsLoading(true);
			const queryParams = new URLSearchParams(filters).toString();

			const response = await axiosInstance.get(`/${resource}?${queryParams}`, {
				headers: {
					// Authorization: `Bearer ${authToken}`,
				},
			});
			setResourceList(response.data);
			return response.data;
		} catch (err) {
			setErrorMessages(err.message || `Error fetching ${resource}`);
		} finally {
			setIsLoading(false);
		}
	};

	const getResourceById = async (id) => {
		try {
			setIsLoading(true);
			const response = await axiosInstance.get(`/${resource}/${id}`, {
				headers: {
					// Authorization: `Bearer ${authToken}`,
				},
			});
			setSelectedResource(response.data.data);
		} catch (err) {
			setErrorMessages(err.message || `Error fetching ${resource} by ID`);
		} finally {
			setIsLoading(false);
		}
	};

	const createResource = async (newResource, filters = {}) => {
		try {
			setIsLoading(true);
			const queryParams = new URLSearchParams(filters).toString();
			const response = await axiosInstance.post(
				`/${resource}?${queryParams}`,
				newResource,
				{
					headers: {
						"Content-Type": "application/json",
						// Authorization: `Bearer ${authToken}`,
					},
				}
			);
			return response.data;
		} catch (err) {
			if (err.response && err.response.status === 401) {
				handleTokenExpiration();
			} else {
				if (err.response && err.response.data && err.response.data.message) {
					const formattedErrors = formatErrorMessages(
						err.response.data.message
					);
					setErrorMessages(formattedErrors);
				} else {
					setErrorMessages(err.message || `Error creating ${resource}`);
				}
			}
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const updateResource = async (id, updatedResource) => {
		try {
			setIsLoading(true);
			const response = await axiosInstance.post(
				`/${resource}/${id}`,
				updatedResource,
				{
					headers: {
						"Content-Type": "application/json",
						// Authorization: `Bearer ${authToken}`,
					},
				}
			);
			return response.data;
		} catch (err) {
			if (err.response && err.response.status === 401) {
				handleTokenExpiration();
			} else {
				if (err.response && err.response.data && err.response.data.message) {
					const formattedErrors = formatErrorMessages(
						err.response.data.message
					);
					setErrorMessages(formattedErrors);
				} else {
					setErrorMessages(err.message || `Error updating ${resource}`);
				}
			}
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const deleteResource = async (id) => {
		try {
			setIsLoading(true);
			await axiosInstance.delete(`/${resource}/${id}`, {
				headers: {
					// Authorization: `Bearer ${authToken}`,
				},
			});
			setErrorMessages({});
		} catch (err) {
			if (err.response && err.response.status === 401) {
				handleTokenExpiration();
			} else {
				setErrorMessages(err.message || `Error deleting ${resource}`);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return {
		authToken,
		resourceList,
		selectedResource,
		isLoading,
		errorMessages,
		getAllResources,
		getResourceById,
		createResource,
		updateResource,
		deleteResource,
	};
};

export default useCrud;
