import React from "react";
import { useTranslation } from "react-i18next";

export default function InvestmentSelect({ currentPage, setCurrentPage }) {
	const { t } = useTranslation();
	const changePageInvest = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="h-[400px] relative">
			<img
				src="https://plus.unsplash.com/premium_photo-1664116707664-012c2fd94fba?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
				alt=""
				className="brightness-75 object-cover w-full h-full overflow-hidden"
			/>

			<div className="absolute z-40 -bottom-8 xl:-bottom-[80px]">
				<div className="flex w-screen justify-center">
					<div className="bg-primary-light flex items-center justify-center rounded-xl p-1 w-full xl:mx-16 xl:h-[160px]">
						<ul className="flex justify-evenly w-full h-full text-white">
							<li
								className={`flex flex-col items-center justify-center cursor-pointer rounded-l-lg w-1/5 h-full p-4 ${
									currentPage === "why-invest"
										? "bg-primary"
										: "bg-primary-light"
								}`}
								id="nav-why-invest"
								onClick={() => changePageInvest("why-invest")}
							>
								<img
									className="li-why-to-invest"
									src="../assets/images/investment/head/Asset alasan berinvestasi.png"
									width="90px"
									height="60px"
									style={{ height: "auto" }}
									alt="Why Invest"
								/>
								<div className="mt-4 hidden md:block">
									<h4>{t("why-invest")}</h4>
								</div>
							</li>

							<li
								className={`flex flex-col items-center justify-center cursor-pointer w-1/5 h-full p-4 ${
									currentPage === "how-invest"
										? "bg-primary"
										: "bg-primary-light"
								}`}
								id="nav-how-invest"
								onClick={() => changePageInvest("how-invest")}
							>
								<img
									className="li-how-to-invest"
									src="../assets/images/investment/head/Asset_how to invest.png"
									width="60px"
									height="60px"
									style={{ height: "auto" }}
									alt="How to Invest"
								/>
								<div className="mt-4 hidden md:block">
									<h4>{t("procedure")}</h4>
								</div>
							</li>

							<li
								className={`flex flex-col items-center justify-center cursor-pointer w-1/5 h-full p-4 ${
									currentPage === "investment-portfolio"
										? "bg-primary"
										: "bg-primary-light"
								}`}
								id="nav-investment-portfolio"
								onClick={() => changePageInvest("investment-portfolio")}
							>
								<img
									className="li-investment-portfolio"
									src="../assets/images/investment/head/Asset_investment portfolio.png"
									width="59px"
									height="60px"
									style={{ height: "auto" }}
									alt="Investment Portfolio"
								/>
								<div className="mt-4 hidden md:block">
									<h4>{t("portfolio")}</h4>
								</div>
							</li>

							<li
								className={`flex flex-col items-center justify-center cursor-pointer w-1/5 h-full p-4 ${
									currentPage === "investment-story"
										? "bg-primary"
										: "bg-primary-light"
								}`}
								id="nav-investment-story"
								onClick={() => changePageInvest("investment-story")}
							>
								<img
									className="li-investment-story"
									src="../assets/images/investment/head/Asset_succes story.png"
									width="59px"
									height="60px"
									style={{ height: "auto" }}
									alt="Investment Story"
								/>
								<div className="mt-4 hidden md:block">
									<h4>{t("success-story")}</h4>
								</div>
							</li>

							<li
								className={`flex flex-col items-center justify-center cursor-pointer rounded-r-lg w-1/5 h-full p-4 ${
									currentPage === "facility-infrastructure"
										? "bg-primary"
										: "bg-primary-light"
								}`}
								id="nav-facility-infrastructure"
								onClick={() => changePageInvest("facility-infrastructure")}
							>
								<img
									className="li-facility-infrastructure"
									src="../assets/images/investment/head/Asset_infrastructure.png"
									width="60px"
									height="60px"
									style={{ height: "auto" }}
									alt="Facility Infrastructure"
								/>
								<div className="mt-4 hidden md:block">
									<h4>{t("facility-infrastructure")}</h4>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
