import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../components/Summary/card";

export default function Summary() {
  const { t } = useTranslation();
  return(
    <div class="bg-white"> 
      <div className="pt-[35px] min-h-[80vh] font-sans px-[150px]">
        <h1 className="text-4xl mt-5 mb-[10px] font-extrabold text-[#333333]">{t("summary")}</h1>
        <div className="mt-[45px] grid grid-cols-2 justify-center items-center gap-[30px] w-full">
          <Card />
          <Card />
          <Card />
          <Card />
        </div>
      </div>
    </div>
  )
}