import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function ({ children }) {
	const [lang, setLang] = useState("english");
	return (
		<div>
			<div className="relative">
				<div className="fixed z-50 w-full">
					<Header lang={lang} setLang={setLang} />
				</div>
			</div>
			<div className="opacity-0">
				<Header lang={lang} setLang={setLang} />
			</div>
			<div className="">
				<img
					src="/assets/images/bg-diamond.png"
					alt=""
					className="fixed -left-[30vw] top-0 -z-20"
				/>
				<img
					src="/assets/images/bg-diamond.png"
					alt=""
					className="fixed -right-[30vw] top-0 -z-20"
				/>
			</div>
			{children}
			<Footer />
		</div>
	);
}
