import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/Breadcrumb";
import InvestmentSelect from "../../components/Investment/InvestmentSelect";
import HeadingUnderline from "../../components/HeadingUnderline";

const Investment = () => {
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState("why-invest");

	const breadcrumbItems = [
		{ label: t("home"), href: "/" },
		{ label: t("invest") },
	];

	return (
		<div className="flex flex-col justify-center">
			<InvestmentSelect
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			<div className="h-32"></div>

			<div className="max-w-6xl w-full mt-16 mx-auto">
				<Breadcrumb items={breadcrumbItems} />
				<h1 className="text-3xl font-bold mb-2 mt-8">{t("reason-invest")}</h1>
				<HeadingUnderline />
			</div>
		</div>
	);
};

export default Investment;
