import React from "react";
import HomeSlider from "../../components/Home/HomeSlider";
import Explore from "../../components/Home/Explore";
import Edition from "../../components/Home/Edition";
import InvestmentDemandForm from "../../components/Home/InvestmentDemandForm";
import AtAGlance from "../../components/Home/AtAGlance";
import DiscoverMaps from "../../components/Home/DiscoverMaps";
import HomeInfo from "../../components/Home/HomeInfo";

const Home = () => {
	return (
		<div>
			<HomeSlider />

			<AtAGlance />

			<Explore />

			<HomeInfo />

			<Edition />

			<InvestmentDemandForm />

			<DiscoverMaps />
		</div>
	);
};

export default Home;
