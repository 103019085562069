import React from "react";
import { useTranslation } from "react-i18next";

export default function AtAGlance() {
	const { t } = useTranslation();
	return (
		<div className="mb-16">
			<div className="px-8 mt-16">
				<h2 className="font-bold text-slate-700">{t("central_java_glance")}</h2>
				<div className="border-b-2 border-primary-light mt-4"></div>
			</div>
			<div className="mx-8 lg:mx-16 mt-8 relative h-[70vh]">
				<div className="absolute inset-0 overflow-hidden">
					<iframe
						src="https://drive.google.com/file/d/1anLO44jGfv979amhM2LFxQ_6QXarfq65/preview"
						title="Central Java Glance Video"
						allow="autoplay"
						className="object-cover w-full h-full rounded-lg"
					></iframe>
				</div>
			</div>
		</div>
	);
}
