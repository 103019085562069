// MessageDialog.js
import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { useTranslation } from "react-i18next";

const MessageDialog = ({ open, onOpenChange, title, description, isError }) => {
	const { t } = useTranslation();
	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Portal>
				<Dialog.Overlay className="DialogOverlay" />
				<Dialog.Content className="DialogContent">
					<Dialog.Title
						className={`text-3xl font-semibold text-center ${
							isError ? "text-red-500" : "text-emerald-500"
						}`}
					>
						{title}
					</Dialog.Title>
					<Dialog.Description className="py-4 text-center text-slate-600">
						{description}
					</Dialog.Description>
					<Dialog.Close asChild>
						<div className="">
							<button
								className="bg-slate-200 w-full text-black text-xl px-8 py-4 rounded-md"
								aria-label="Close"
								onClick={() => onOpenChange(false)}
							>
								{t("close")}
							</button>
						</div>
					</Dialog.Close>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default MessageDialog;
