import React from 'react';
import { useTranslation } from 'react-i18next';

const VisiMisi = () => {
  const { t } = useTranslation();

  return (
    <div className="visi-misi space-y-6" id="visi-misi">
      <h3 className="font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2">
        {t('vision-mission')}
      </h3>
      
      <div className="sub-title bg-[rgba(224,170,62,0.2)] w-24 rounded-md font-semibold text-lg text-center my-5 uppercase">
        {t('purpose')}
      </div>
      <ol className="bullet-number-container list-none pl-0 font-semibold space-y-2" style={{ counterReset: 'item-counter' }}>
        <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
          <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
          <p>{t('desc-purpose-1')}</p>
        </li>
        <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
          <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
          <p>{t('desc-purpose-2')}</p>
        </li>
        <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
          <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
          <p>{t('desc-purpose-3')}</p>
        </li>
        <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
          <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
          <p>{t('desc-purpose-4')}</p>
        </li>
        <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
          <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
          <p>{t('desc-purpose-5')}</p>
        </li>
      </ol>

      <div className="sub-title bg-[rgba(224,170,62,0.2)] w-24 rounded-md font-semibold text-lg text-center my-5 uppercase">
        {t('vision')}
      </div>
      <div className="bullet-number-container font-semibold text-justify">{t('desc-vision')}</div>

      <div className="sub-title bg-[rgba(224,170,62,0.2)] w-24 rounded-md font-semibold text-lg text-center my-5 uppercase">
        {t('mission')}
      </div>
      <div className="mission space-y-2">
        <ol className="bullet-number-container list-none pl-0 font-semibold space-y-2" style={{ counterReset: 'item-counter' }}>
          <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
            <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
            <p>{t('mission-1')}</p>
          </li>
          <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
            <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
            <p>{t('mission-2')}</p>
          </li>
          <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
            <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
            <p>{t('mission-3')}</p>
          </li>
          <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
            <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
            <p>{t('mission-4')}</p>
          </li>
          <li className="flex items-center space-x-2" style={{ counterIncrement: 'item-counter' }}>
            <span className="before:content-[counter(item-counter)'.'] before:font-semibold"></span>
            <p>{t('mission-5')}</p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default VisiMisi;
