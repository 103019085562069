import axios from "axios";

const axiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	timeout: 10000,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosInstance.interceptors.request.use(
	(config) => {
		config.headers["Custom-Header"] = "CustomHeaderValue";
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response) {
			console.error("Error response:", error.response);
		} else if (error.request) {
			console.error("No response received:", error.request);
		} else {
			console.error("Error setting up request:", error.message);
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
