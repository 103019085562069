import React from "react";
import Accordion from "./accordion";
import { useTranslation } from "react-i18next";

export default function Investment() {
  const { t } = useTranslation();
  return(
    <>
      <div className="space-y-6">
        <h3 className="font-extrabold text-xl uppercase border-b-4 border-[#c3c3c3] pb-[10px] mt-4 mb-0">
          {t("investment")}
        </h3>
        <Accordion title={t("faq-investment.question-1")} content={t("faq-investment.answer-1")}/>
        <Accordion title={t("faq-investment.question-2")} content={t("faq-investment.answer-2")}/>
        <Accordion title={t("faq-investment.question-3")} content={t("faq-investment.answer-3")}/>
      </div>
    </>
  )
}