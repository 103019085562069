import React from "react";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items }) => {
	return (
		<nav className="flex items-center space-x-2 text-sm">
			{items?.map((item, index) => (
				<div key={index} className="flex items-center">
					{item.href ? (
						<Link to={item.href} className=" hover:underline">
							{item.label}
						</Link>
					) : (
						<span className="text-gray-500">{item.label}</span>
					)}
					{index < items.length - 1 && (
						<ChevronRightIcon className="w-4 h-4 mx-1 text-gray-400" />
					)}
				</div>
			))}
		</nav>
	);
};

export default Breadcrumb;
