import React from "react";
import { useTranslation } from "react-i18next";

export default function Explore() {
	const { t } = useTranslation();
	return (
		<>
			<div className="bg-primary-gradient min-h-96 text-white text-center py-16">
				<h4 className="px-8">{t("keistimewaan_jateng")}</h4>
				<div className="flex flex-col xl:flex-row gap-8 mx-8 lg:mx-32 justify-center">
					<a href="/perdagangan/perdagangan" className="xl:w-1/3">
						<div className="mt-8 relative h-[540px] group cursor-pointer rounded-lg w-full">
							<div className="absolute inset-0 overflow-hidden rounded-lg">
								<img
									src="../assets/images/home/perdagangan-home.webp"
									alt=""
									className="brightness-75 object-cover w-full h-full rounded-lg overflow-hidden"
								/>
							</div>
							<div className="relative z-10 p-16 pt-32 bg-black h-full rounded-lg bg-opacity-0 group-hover:bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all">
								<h1>
									<i className="fa fa-ship"></i>
								</h1>
								<h1 className="text-white mb-8 mt-4">{t("commerce")}</h1>
								<p>{t("card_commerce")}</p>
							</div>
							<div className="flex w-full absolute z-40 -bottom-8 items-center">
								<div className="bg-secondary px-8 py-4 rounded-xl w-full mx-16">
									<h3 className="text-white group-hover:hidden">
										{t("trade")}
									</h3>
									<h3 className="text-white hidden group-hover:block">
										{t("more")}
									</h3>
								</div>
							</div>
						</div>
					</a>

					<a href="/pariwisata/pariwisata" className="xl:w-1/3">
						<div className="mt-8 relative h-[540px] group cursor-pointer rounded-lg w-full">
							<div className="absolute inset-0 overflow-hidden rounded-lg">
								<img
									src="../assets/images/home/Home_Pariwisata.webp"
									alt=""
									className="brightness-75 object-cover w-full h-full rounded-lg overflow-hidden"
								/>
							</div>
							<div className="relative z-10 p-16 pt-32 bg-black h-full rounded-lg bg-opacity-0 group-hover:bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all">
								<h1>
									<i className="fa fa-plane"></i>
								</h1>
								<h1 className="text-white mb-8 mt-4">{t("tourism")}</h1>
								<p>{t("card_tourism")}</p>
							</div>
							<div className="flex w-full absolute z-40 -bottom-8 items-center">
								<div className="bg-secondary px-8 py-4 rounded-xl w-full mx-16">
									<h3 className="text-white group-hover:hidden">
										{t("tourism")}
									</h3>
									<h3 className="text-white hidden group-hover:block">
										{t("more")}
									</h3>
								</div>
							</div>
						</div>
					</a>

					<a href="/investasi/investment" className="xl:w-1/3">
						<div className="mt-8 relative h-[540px] group cursor-pointer rounded-lg w-full">
							<div className="absolute inset-0 overflow-hidden rounded-lg">
								<img
									src="../assets/images/home/investasi-home.webp"
									alt=""
									className="brightness-75 object-cover w-full h-full rounded-lg overflow-hidden"
								/>
							</div>
							<div className="relative z-10 p-16 pt-32 bg-black h-full rounded-lg bg-opacity-0 group-hover:bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all">
								<h1>
									<i className="fa fa-money"></i>
								</h1>
								<h1 className="text-white mb-8 mt-4">{t("invest")}</h1>
								<p>{t("card_invest")}</p>
							</div>
							<div className="flex w-full absolute z-40 -bottom-8 items-center">
								<div className="bg-secondary px-8 py-4 rounded-xl w-full mx-16">
									<h3 className="text-white group-hover:hidden">
										{t("invest")}
									</h3>
									<h3 className="text-white hidden group-hover:block">
										{t("more")}
									</h3>
								</div>
							</div>
						</div>
					</a>
				</div>
				<div className="my-16"></div>
			</div>
		</>
	);
}
