import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import HeadingUnderline from "../HeadingUnderline";
import useCrud from "../../hooks/useCRUD";
import MessageDialog from "../MessageDialog"; // Adjust the path as needed

const ContactForm = () => {
	const { t } = useTranslation();
	const [successOpen, setSuccessOpen] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { createResource, isLoading } = useCrud("messages");

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		try {
			const res = await createResource(data);
			setSuccessOpen(true);
			reset();
		} catch (error) {
			console.log(error);
			setErrorMessage(error.message || t("error.generic"));
			setErrorOpen(true);
		}
	};

	return (
		<>
			<div className="text-left">
				<h1 className="text-3xl font-bold mb-2 mt-24">{t("contact-us")}</h1>
				<HeadingUnderline />

				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col items-end mx-8 mt-8"
				>
					<div className="flex flex-col lg:flex-row w-full gap-8">
						<div className="w-full">
							<div className="flex flex-col">
								<label htmlFor="name">{t("name")}</label>
								<input
									className="input-main"
									type="text"
									placeholder={t("placeholder.name")}
									{...register("name", { required: t("required.name") })}
								/>
								{errors.name && (
									<span className="text-rose-500">{errors.name.message}</span>
								)}
							</div>

							<div className="flex flex-col">
								<label htmlFor="organization">{t("organization")}</label>
								<input
									className="input-main"
									type="text"
									placeholder={t("placeholder.organization")}
									{...register("organization", {
										required: t("required.organization"),
									})}
								/>
								{errors.organization && (
									<span className="text-rose-500">
										{errors.organization.message}
									</span>
								)}
							</div>

							<div className="flex flex-col">
								<label htmlFor="email">{t("email")}</label>
								<input
									className="input-main"
									type="email"
									placeholder={t("placeholder.email")}
									{...register("email", {
										required: t("required.email"),
										pattern: {
											value: /^\S+@\S+$/i,
											message: t("emailFormat"),
										},
									})}
								/>
								{errors.email && (
									<span className="text-rose-500">{errors.email.message}</span>
								)}
							</div>

							<div className="flex flex-col">
								<label htmlFor="phone_number">{t("phoneNumber")}</label>
								<input
									className="input-main"
									type="text"
									placeholder={t("placeholder.phoneNumber")}
									{...register("phone_number", {
										required: t("required.phoneNumber"),
									})}
								/>
								{errors.phone_number && (
									<span className="text-rose-500">
										{errors.phone_number.message}
									</span>
								)}
							</div>
						</div>

						<div className="flex flex-col w-full">
							<label htmlFor="messages">{t("messages")}</label>
							<textarea
								className="input-main h-full"
								placeholder={t("placeholder.messages")}
								{...register("messages", { required: t("required.messages") })}
							/>
							{errors.messages && (
								<span className="text-rose-500">{errors.messages.message}</span>
							)}
						</div>
					</div>

					<div className="ml-auto mt-2">
						<button
							className="bg-[#25A5B0] text-white text-xl px-8 py-4 rounded-md"
							id="button-send-message"
							type="submit"
						>
							{isLoading ? t("sending") : t("send")}
						</button>
					</div>
				</form>
			</div>
			<div className="my-32"></div>

			{/* Success dialog */}
			<MessageDialog
				open={successOpen}
				onOpenChange={setSuccessOpen}
				title={t("message-sent")}
				description={t("thank-you-contacting")}
			/>

			{/* Error dialog */}
			<MessageDialog
				open={errorOpen}
				onOpenChange={setErrorOpen}
				title={t("error")}
				description={t("failed-to-send-message")}
				isError={true}
			/>
		</>
	);
};

export default ContactForm;
