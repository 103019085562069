import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useCrud from "../../../hooks/useCRUD";

Chart.register(...registerables);

export default function MacroeconomicsChart({
	category,
	translationKey,
	yTitle,
}) {
	const { i18n, t } = useTranslation();
	const {
		resourceList = {},
		getAllResources,
		isLoading,
	} = useCrud("macroeconomy/graph");

	useEffect(() => {
		if (category) {
			getAllResources({ lang: i18n.language, category });
		}
	}, [i18n.language, category]);

	const data = {
		labels: resourceList?.data?.labels || [],
		datasets: [
			{
				data: resourceList?.data?.data || [],
				backgroundColor: "transparent",
				borderColor: "#e0aa3e",
				borderWidth: 1,
				fill: true,
				tension: 0.5,
				pointRadius: 0,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: yTitle ?? "YoY",
				},
				ticks: {
					stepSize: 3,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: true,
				mode: "index",
				intersect: false,
			},
		},
	};

	return (
		<div className="border w-full border-emerald-700 rounded p-4 cursor-pointer">
			<div>
				<b className="" style={{ color: "#404040" }}>
					{t(translationKey)}
				</b>
				<div style={{ height: "120px" }} className="mt-4">
					<Line data={data} options={options} />
				</div>
			</div>
		</div>
	);
}
