// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationID from "./locales/id/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationZH from "./locales/zh/translation.json";

const resources = {
	en: {
		translation: translationEN,
	},
	id: {
		translation: translationID,
	},
	ja: {
		translation: translationJA,
	},
	ko: {
		translation: translationKO,
	},
	zh: {
		translation: translationZH,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "en",
		// debug: true,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
