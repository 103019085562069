import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Investment from "./pages/Investment";
import ContactUs from "./pages/ContactUs";
import Layout from "./components/Layout";
import PostPage from "./pages/Discover";
// import Berita from "./pages/Berita";
import AboutUs from "./pages/AboutUs";
import ChartDetail from "./pages/Home/ChartDetail";
import Summary from "./pages/Summary";
import Faq from "./pages/Faq";

function App() {
	useEffect(() => {
		document.title = "Keris Jateng";
	}, []);

	return (
		<div className="App">
			<Router>
				<Layout>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/statistic/:postName" element={<ChartDetail />} />

						<Route path="/discover/:postName" element={<PostPage />} />

						<Route path="/investment" element={<Investment />} />
						<Route path="/contact_us" element={<ContactUs />} />
						{/* <Route path="/b" element={<Berita />} /> */}
						<Route path="/about_us" element={<AboutUs />} />
						<Route path="/summary" element={<Summary />} />
						<Route path="/faq" element={<Faq />} />
					</Routes>
				</Layout>
			</Router>
		</div>
	);
}

export default App;
