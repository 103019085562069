import React from "react";
import Accordion from "./accordion";
import { useTranslation } from "react-i18next";

export default function Tourism() {
  const { t } = useTranslation();
  return(
    <>
      <div className="space-y-6">
        <h3 className="font-extrabold text-xl uppercase border-b-4 border-[#c3c3c3] pb-[10px] mt-4 mb-0">
          {t("tourism")}
        </h3>
        <Accordion title={t("faq-tourism.question-1")} content={t("faq-tourism.answer-1")}/>
        <Accordion title={t("faq-tourism.question-2")} content={t("faq-tourism.answer-2")}/>
      </div>
    </>
  )
}