import React, { useState } from "react";
import 'font-awesome/css/font-awesome.min.css';

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  }

  return(
    <>
        <div className="w-100%" onClick={toggleAccordion}>
        <div className={`flex justify-between items-center border border-[#c1c1c1] rounded-md py-[10px] ps-[22px] pe-[10px] ${isOpen ? 'bg-[#c1c1c1]' : 'bg-white'}`}>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
            <i className={`fa fa-caret-${isOpen ? 'up' : 'down'}`}></i>
          </div>
          <div className={`mt-2 bg-white rounded transition-max-height duration-700 ease-in-out overflow-hidden ${isOpen ? 'max-h-96' : 'max-h-0'}`} style={{ boxShadow: "-1px 2px 17px -5px rgba(0, 0, 0, 0.75)"}}>
            <div className="p-[5px] ps-[10px] border border-[#c1c1c1] rounded">
              <p className="leading-[25px]" dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
          </div>
        </div>       
    </>
  )
}

export default Accordion;