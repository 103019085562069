import React from 'react';
import { useTranslation } from 'react-i18next';

const KegiatanKami = () => {
  const { t } = useTranslation();

  return (
    <div className="kegiatan-kami space-y-8 pb-16" id="kegiatan-kami">
      <h3 className="font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2">
        {t('our-activities')}
      </h3>

      {[...Array(15)].map((_, i) => (
        <div className="kegiatan-kami-container text-center border-b-4 border-gray-300 pb-6" key={i}>
          <h4 className="font-semibold text-lg">{t(`activities-title-${i + 1}`)}</h4>
          <img 
            src={`/assets/images/about_us/kegiatan-${i + 1}.png`} 
            alt="" 
            className="w-3/4 max-w-md mx-auto my-4" 
          />
          <p className="text-sm pt-3">{t(`activities-description-${i + 1}`)}</p>
        </div>
      ))}
    </div>
  );
};

export default KegiatanKami;
