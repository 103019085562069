import React from "react";
import { useParams } from "react-router-dom";

export default function Index() {
	const { postName } = useParams();

	return (
		<div>
			<h1>Post Name: {postName}</h1>
		</div>
	);
}
