import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

import useCrud from "../../hooks/useCRUD";
import MacroeconomicsChart from "./MacroeconomicsChart/MacroeconomicsChart";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

// Register Chart.js components
Chart.register(...registerables);

const baseData = {
	labels: ["Q2 2023", "Q3 2023", "Q4 2023", "Q1 2024"],
	datasets: [
		{
			data: [250, 300, 150, 300, 100],
			backgroundColor: "transparent",
			borderColor: "rgba(224, 170, 62, 0.93)",
			borderWidth: 1,
			fill: true,
			tension: 0.5,
			pointRadius: 0,
		},
	],
};

// Chart options
const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			beginAtZero: true,
			title: {
				display: true,
				text: "internationalYLabel",
			},
			ticks: {
				stepSize: 3,
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: true,
			mode: "index",
			intersect: false,
		},
	},
};

export default function HomeInfo() {
	const { i18n, t } = useTranslation();
	const [berita, setBerita] = useState({});
	const [peluangInvestasi, setPeluangInvestasi] = useState({});
	const { getAllResources, isLoading } = useCrud("post");
	const [seeAllChart, setSeeAllChart] = useState(false);

	async function fetchData() {
		try {
			const resBerita = await getAllResources({
				lang: i18n.language,
				type: "berita",
				limit: 6,
			});
			setBerita(resBerita);
			const resPeluangInvestasi = await getAllResources({
				lang: i18n.language,
				type: "peluang_investasi",
				meta: true,
				limit: 6,
			});
			setPeluangInvestasi(resPeluangInvestasi);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, [i18n.language]);
	const [showMacroeconomicContent, setShowMacroeconomicContent] =
		useState(false);
	const [showNewsContent, setShowNewsContent] = useState(false);
	const [showOpportunityContent, setShowOpportunityContent] = useState(false);

	const ChartComponent = ({ data, options }) => {
		return (
			<div style={{ height: "120px" }} className="mt-4">
				<Line data={data} options={options} />
			</div>
		);
	};

	const ViewAll = ({ onClick, isVisible }) => {
		return (
			<div className="flex w-full justify-end">
				{!isVisible && (
					<p
						className="text-primary font-bold cursor-pointer mt-4"
						onClick={onClick}
					>
						{t("see-all")} <i className="fa fa-caret-right"></i>
					</p>
				)}
			</div>
		);
	};

	return (
		<div className="flex items-center justify-center py-16 px-8 xl:px-16 bg-white">
			<div className="flex flex-col xl:flex-row justify-between w-full gap-8">
				<div className="xl:w-1/3">
					<h3 className="font-bold">{t("makroekonomi_jateng")}</h3>
					<div className="flex flex-col mt-8 gap-4">
						<Link to="/statistic/economic-growth">
							<MacroeconomicsChart
								category="economic-growth"
								translationKey="economic_growth"
							/>
						</Link>

						<Link to="/statistic/inflation">
							<MacroeconomicsChart
								category="inflation"
								translationKey="inflation"
							/>
						</Link>
						<Link to="/statistic/invest-realization">
							<MacroeconomicsChart
								category="invest-realization"
								translationKey="investment-realization"
								yTitle="Triliun Rupiah"
							/>
						</Link>
						<MacroeconomicsChart
							category="international-trade"
							translationKey="international-trade"
							yTitle="Milion Dollars"
						/>

						{seeAllChart && (
							<>
								<a href="/links/summary">
									<div className="border w-full border-emerald-700 rounded p-4">
										<div>
											<b className="" style={{ color: "#404040" }}>
												{t("assesment-economy")}
											</b>
											<div className="mt-4">
												<div className="bg-primary-light h-4"></div>
											</div>
										</div>
									</div>
								</a>

								<a href="/links/central_java_statistics">
									<div className="border w-full border-emerald-700 rounded p-4">
										<div>
											<b className="" style={{ color: "#404040" }}>
												{t("statistic")}
											</b>
											<div className="mt-4">
												<div className="bg-primary-light h-4"></div>
											</div>
										</div>
									</div>
								</a>
							</>
						)}
					</div>
					{!seeAllChart && (
						<div className="" onClick={() => setSeeAllChart(true)}>
							<ViewAll />
						</div>
					)}
					{showMacroeconomicContent && (
						<React.Fragment>
							<a href={"links/summary"} className="block">
								<div className="col-md-12 mt-4 pt-4 w-full border border-emerald-700 rounded p-6 hover:border-[#372a3c] hover:bg-[#372a3c] group">
									<b className="text-gray-700 group-hover:text-[#156ace]">
										{t("assesment-economy")}
									</b>
									<hr className="my-5 border-y-8 border-gold" />
								</div>
							</a>
							<a href={"links/central_java_statistics"}>
								<div className="col-md-12 mt-4 pt-4 w-full border border-emerald-700 rounded p-6 hover:border-[#db7455] hover:bg-[#db7455] group">
									<b className="text-gray-700  group-hover:text-[#156ace]">
										{t("statistic")}
									</b>
									<hr className="my-5 border-y-8 border-gold" />
								</div>
							</a>
						</React.Fragment>
					)}
				</div>
				<div className="xl:w-1/3">
					<h3 className="font-bold">{t("newsevent")}</h3>
					<div className="flex flex-col mt-8 gap-4">
						{berita?.data?.data?.map((item, index) => {
							const postDate =
								typeof item.post_date === "string"
									? new Date(item.post_date)
									: item.post_date;

							if (isNaN(postDate)) {
								console.error("Invalid date:", item.post_date);
								return null;
							}

							const day = postDate.getDate();
							const month = postDate
								.toLocaleString("default", { month: "short" })
								.toUpperCase();
							const year = postDate.getFullYear();

							return (
								<a
									href={`/b/${item.post_name}`}
									key={index}
									className="w-full flex items-center gap-4"
								>
									<div className="bg-primary-dark gap-1 flex flex-col justify-center font-bold items-center text-white min-w-[80px] h-[70px] rounded-xl">
										<span className="text-2xl leading-none">{day}</span>
										<span className="text-xs leading-none">{month}</span>
										<span className="text-xs leading-none">{year}</span>
									</div>
									<div>
										<h6 className="line-clamp-2 font-semibold">
											{item.post_title}
										</h6>
										<small className="text-gray-500 line-clamp-2">
											{item.post_content}
										</small>
									</div>
								</a>
							);
						})}
					</div>
					<a href="/b">
						<ViewAll />
					</a>
				</div>
				<div className="xl:w-1/3">
					<h3 className="font-bold">{t("opportunities")}</h3>
					<div className="border w-full border-emerald-700 rounded mt-8 p-8">
						{peluangInvestasi && (
							<Swiper
								modules={[Navigation, Autoplay]}
								navigation={{
									nextEl: ".swiper-button-next",
									prevEl: ".swiper-button-prev",
								}}
								loop={peluangInvestasi?.data?.data?.length > 1}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false,
								}}
								className="mySwiper mb-4 rounded-lg"
							>
								{peluangInvestasi?.data?.data?.map((item, index) => (
									<SwiperSlide key={index}>
										<a href={`/p/${item.post_name}`} key={index}>
											<div className="bg-slate-900 h-[260px] flex flex-col justify-center text-white">
												<img
													src={`${process.env.REACT_APP_IMAGE_URL}${item?.post_meta[0]?.meta_value}`}
													alt=""
													className="object-fit w-full h-full"
												/>
											</div>
										</a>
									</SwiperSlide>
								))}

								<div className="swiper-button-prev text-white border-white"></div>
								<div className="swiper-button-next text-white border-white"></div>
							</Swiper>
						)}

						{peluangInvestasi?.data?.data?.map((item, index) => {
							return (
								<a href={`/p/${item.post_name}`} key={index}>
									<h6
										className={`font-semibold mt-2 ${
											index % 2 ? "text-gray-500" : "text-gray-700"
										}`}
									>
										{item.post_title}
									</h6>
								</a>
							);
						})}
					</div>
					<a href="/investasi/investment">
						<ViewAll />
					</a>
				</div>
			</div>
		</div>
	);
}
