import React, { useState } from "react";
import AboutSection from "../../components/AboutUs/AboutSection";
import VisiMisi from "../../components/AboutUs/VisiMisi";
import KegiatanKami from "../../components/AboutUs/KegiatanKami";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import 'font-awesome/css/font-awesome.min.css';

const AboutUs = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("about");

  const breadcrumbItems = [
    { label: <><i className="ms-2 fa fa-home"></i> {t("home")}</>, href: "/" },
    { label: t("about-us") },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getButtonClassName = (tab) => 
    `block w-full text-left my-2 ${activeTab === tab ? "font-bold border-l-[10px] border-gold bg-[#e0aa3e33] py-3 px-[30px] uppercase" : "py-3 px-[40px] uppercase"}`;

  return (
    <div className="relative">
      <div className="flex justify-center relative z-10">
        <div className="max-w-6xl w-full mt-[30px] px-4">
          <Breadcrumb items={breadcrumbItems}/>

          <div className="flex mt-7">
            {/* Sidebar Navigation */}
            <div className="w-1/4 pt-12 bg-[#eaeaea] min-h-[713px] max-h-screen text-[20px]">
              <button
                className={getButtonClassName("about")}
                onClick={() => handleTabChange("about")}
              >
                {t("about-us-section")}
              </button>
              <button
                className={getButtonClassName("visiMisi")}
                onClick={() => handleTabChange("visiMisi")}
              >
                {t("vision-mission")}
              </button>
              <button
                className={getButtonClassName("kegiatanKami")}
                onClick={() => handleTabChange("kegiatanKami")}
              >
                {t("our-activities")}
              </button>
            </div>

            {/* Content Area */}
            <div className="w-3/4 p-4 space-y-8">
              {activeTab === "about" && <AboutSection />}
              {activeTab === "visiMisi" && <VisiMisi />}
              {activeTab === "kegiatanKami" && <KegiatanKami />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
