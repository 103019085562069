import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

import { Link, useParams } from "react-router-dom";
import useCrud from "../../../hooks/useCRUD";
import HeadingUnderline from "../../../components/HeadingUnderline";

export default function ChartDetail() {
	const { i18n, t } = useTranslation();
	const [makroekonomiDetail, setMakroekonomiDetail] = useState({});
	const { postName } = useParams();
	const { getAllResources, isLoading } = useCrud(`post/${postName}`);

	async function fetchData() {
		try {
			const res = await getAllResources({
				lang: i18n.language,
				meta: true,
			});
			setMakroekonomiDetail(res);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, [i18n.language]);

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="w-full max-w-3xl my-16">
				<h2 className="font-bold mb-4">
					{makroekonomiDetail?.data?.post_title}
				</h2>
				<HeadingUnderline />
				<div
					className="mt-8 post-content"
					dangerouslySetInnerHTML={{
						__html: makroekonomiDetail?.data?.post_content,
					}}
				></div>
			</div>
		</div>
	);
}
